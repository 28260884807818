<template>
	<div class="app-container">
		<el-card class="elCard">
			<div>
				<i class="el-icon-search"></i>
				<span>查询条件</span>
				<el-button style="float: right; margin-left: 5px;" @click="resetForm('searchForm')" size="small">清空</el-button>
				<el-button style="float: right" @click="searchTable()" type="primary" size="small" icon="el-icon-search">查询</el-button>
			</div>
			<div style="margin-top: 15px">
				<el-form :inline="true" ref="searchForm" :model="searchForm" size="small" label-width="100px">
					<el-form-item label="模板名称：" prop="name">
						<el-input v-model="searchForm.name" placeholder="请输入模板名称"></el-input>
					</el-form-item>
					<el-form-item label="是否启用：" prop="status">
						<el-select v-model="searchForm.status" clearable placeholder="请选择" autocomplete="off" style="max-width: 200px;">
						    <el-option v-for="item in statusArr" :key="item.value" :label="item.label" :value="item.value"></el-option>
						</el-select>
					</el-form-item>
				</el-form>
			</div>
		</el-card>
		<el-row>
			<el-button type="primary" size="medium" icon="el-icon-circle-plus-outline" @click="append()">新增</el-button>
			<el-popover placement="bottom" width="150" v-model="visible" style="float: right;">
				<el-checkbox-group v-model="checkList" @change="checkedChange">
				    <el-checkbox v-for="item in checkArr" :label="item.val">{{item.label}}</el-checkbox>
				</el-checkbox-group>
				<el-button type="primary" size="medium" icon="el-icon-s-grid" circle slot="reference"></el-button>
			</el-popover>
			<el-button style="float: right; margin-right: 5px;" size="medium" type="primary" icon="el-icon-refresh-left" circle @click="page = 1;getList()"></el-button>
		</el-row>
		<el-card>
			<el-table :data="tableData" :stripe="true" border v-loading="listLoading" style="width: 100%" @selection-change="handleSelectionChange">
				<el-table-column type="selection" width="55"></el-table-column>
				<el-table-column v-for="item in checkArr" v-if="item.dis" :prop="item.val" :label="item.label" min-width="110">
					<template slot-scope="scope">
						<span v-if="item.val == 'status'">{{scope.row.status == 0 ? '禁用' : '启用'}}</span>
						<span v-else>{{scope.row[item.val]}}</span>
					</template>
				</el-table-column>
				<el-table-column prop="createdTxStamp" label="创建日期" min-width="140"></el-table-column>
				<el-table-column label="操作" min-width="140">
					<template slot-scope="scope">
						<el-button class="marginRight10" type="primary" size="mini" icon="el-icon-edit" @click="editTable(scope.$index, scope.row)">修改</el-button>
						<!-- <el-button type="danger" size="mini" icon="el-icon-delete" @click="deleteTable(scope.$index, scope.row)">删除</el-button> -->
						<el-switch v-model="scope.row.status" @change="switChange($event, scope.row)" active-color="#409eff" inactive-color="#ff4949" active-text="启用" inactive-text="禁用" :active-value="1" :inactive-value="0"></el-switch>
					</template>
				 </el-table-column>
			</el-table>
			<!-- 分页 -->
			<el-pagination @current-change="handleCurrentChange" :current-page="page" layout="total, prev, pager, next, jumper" :total="total"></el-pagination>
		</el-card>
		<el-dialog :title="dialogTitle" :visible.sync="dialogFormVisible">
		  <el-form :model="form" :rules="rules" ref="ruleForm" :label-width="formLabelWidth">
		    <el-form-item label="模板名称：" prop="name">
		      <el-input v-model="form.name" placeholder="请输入模板名称" autocomplete="off"></el-input>
		    </el-form-item>
		    <el-form-item label="备注：">
		      <el-input type="textarea" v-model="form.remark" placeholder="请输入备注" autocomplete="off"></el-input>
		    </el-form-item>
		    <el-form-item prop="weight">
				<span slot="label">创建流程：<el-button type="success" size="mini" class="addAnsBtn" @click="resetStep">重置</el-button></span>
				<el-button class="marginLeft0 marginRight10" size="mini" v-for="(item, index) in stepArr" @click="stepClick(index)">{{item.name}}</el-button>
				<el-steps class="elSteps" align-center>
					<el-step v-for="item in form.weight" :title="item.stageName" :description="item.name ? item.name : item.typeStageName"></el-step>
				</el-steps>
		    </el-form-item>
		  </el-form>
		  <div slot="footer" class="dialog-footer">
		    <el-button @click="dialogFormVisible = false">取 消</el-button>
		    <el-button type="primary" @click="dialogConfirm('ruleForm')">确 定</el-button>
		  </div>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				listLoading: false,
				dialogTitle: '修改数据',
				page: 1,
				searchForm: {
					name: '',
					status: 1
				},//条件查询form
				statusArr: [{
					value: 0,
					label: "禁用"
				}, {
					value: 1,
					label: "启用"
				}],//是否启用
				tableData: [],//表格
				multipleSelection: [],//表格选中数组
				total: 0,//分页总条数
				dialogFormVisible: false,//修改、新增弹窗
				formLabelWidth: '120px',//修改、新增弹窗-label宽度
				typeArr: ["判断题", "单选题", "多选题"],//修改、新增弹窗-类型选择数组
				form: {
					name: '',
					remark: '',
					weight: []
				},//修改、新增弹窗-表单内容
				rules: {
					name: [{ required: true, message: '请输入模板名称', trigger: 'blur' }],
					// remark: [{ required: true, message: '请输入备注', trigger: 'blur' }],
					weight: [{ required: true, message: '请选择流程', trigger: 'blur' }]
				},
				visible: false,
				checkArr: [{label: "模板名称", val: "name", dis: true}, {label: "是否启用", val: "status", dis: true}, {label: "备注", val: "remark", dis: true}],
				checkList: ["name", "status", "remark"],
				stepArr: []
			}
		},
		
		activated() {
		    this.getList();
		    this.getBtn();
		},
		components: {
			
		},
		created(){
			// this.getList();
			// this.getBtn();
		},
		methods:{
			getList() {
				this.listLoading = true;
				// 查询内容转数组
				let obj = this.searchForm;
				let arr = Object.keys(obj);
				let arrs = [];
				arr.forEach(item => {
					let o = {};
					o.col = item;
					o.val = obj[item];
					if(item == "name") {
						o.type = "like";
					} else {
						o.type = "=";
					}
					arrs.push(o);
				})
				
				let data = {
					entity: 'FaExamGameTemplate',
					page: this.page,
					pageSize: 10,
					filter: JSON.stringify(arrs),
					// sidx: 'sort',
					// sord: 'asc'
				}
				this.$comjs.ajax.getAjax('/jqGrid/faCommonlist', data, this, res => {
					this.tableData = res.rows;
					this.total = res.total;
					this.listLoading = false;
				});
			},
			// 获取赛制阶段
			getBtn() {
				let datas = {
					entity: 'ViewFaExamTypeStageRef',
					filter: '[{"col":"status","type":"=","val":"1"}]',
					sidx: 'sort',
					sord: 'asc'
				}
				this.$comjs.ajax.getAjax('/jqGrid/findFaList', datas, this, res => {
					this.stepArr = res.list;
				});
			},
			// 搜索
			searchTable() {
				this.page = 1;
				this.getList();
			},
			// 重置
			resetForm(formName) {
				this.$refs[formName].resetFields();
				this.page = 1;
				this.getList();
			},
			// 新增
			append() {
				this.dialogFormVisible = true;
				this.dialogTitle = "新增数据";
				this.form = {
					id: '',
					name: '',
					remark: '',
					weight: []
				}
			},
			// 表格选中
			handleSelectionChange(val) {
				this.multipleSelection = val;
			},
			// 分页
			handleCurrentChange(val) {
				this.page = val;
				this.getList();
			},
			// 修改
			editTable(index, row) {
				this.dialogFormVisible = true;
				this.dialogTitle = "修改数据";
				let data = {
					id: row.id
				}
				this.$comjs.ajax.getAjax('/fa/exam/game/gameTemplateDetail', data, this, res => {
					this.form = {
						id: res.data.id,
						name: res.data.name,
						remark: res.data.remark,
						weight: res.data.refList
					}
				});
			},
			// 删除
			// deleteTable(index, row) {
			// 	let data = {
			// 		entity: 'FaExamGameTemplate',
			// 		id: row.id
			// 	}
			// 	this.$comjs.ajax.postAjax('/jqGrid/delete', data, this, res => {
			// 		this.tableData.splice(index, 1);
			// 	});
			// },
			// 表格-是否启用
			switChange(val, row) {
				let data = {
					id: row.id,
					status: val,
					entity: 'FaExamGameTemplate'
				}
				this.$comjs.ajax.postAjax('/jqGrid/changeStatus', data, this, res => {
					this.$message({
						message: res.message,
						type: 'success'
					});
					this.getList();
				});
			},
			// 创建流程
			stepClick(index) {
				this.stepArr[index].typeStageId = this.stepArr[index].id;
				this.form.weight.push(this.stepArr[index]);
			},
			// 重置流程
			resetStep() {
				this.form.weight = [];
			},
			// 修改/新增-确定
			dialogConfirm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						let str = '';
						this.form.weight.forEach(item => {
							if(str == "") {
								str = item.typeStageId;
							} else {
								str = str + ',' + item.typeStageId
							}
						})
						let data = {
							id: this.form.id,
							name: this.form.name,
							remark: this.form.remark,
							typeStageIds: str
						}
						this.$confirm('是否提交数据', '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {
							this.$comjs.ajax.postAjax('/fa/exam/game/editGameTemplate', data, this, res => {
								this.dialogFormVisible = false;
								this.$refs[formName].resetFields();
								this.$message({
									message: '保存成功！',
									type: 'success',
									duration: 1000
								});
								this.getList();
							});
						}).catch(() => {
							
						});
					}
				});
			},
			// 多选框
			checkedChange(value) {
				let arr = this.checkArr;
				arr.forEach(item => {
					item.dis = false;
				})
				value.forEach(item => {
					let index = arr.findIndex(d => d.val === item);
					arr[index].dis = true;
				})
				this.checkArr = arr;
			}
		},
		computed:{
			
		}
	}
</script>

<style lang="scss" scoped>
	.app-container {
		padding: 20px;
	}
	.titles {
		border: 1px solid #E4E7ED;
		border-radius: 4px;
		line-height: 60px;
	}
	.elCard {
		margin-top: 20px;
	}
	.el-row {
		padding: 20px 0;
	}
	.el-pagination {
		margin-top: 15px;
		text-align: left;
	}
	.addAnsBtn {
		padding: 4px;
	}
</style>
<style type="text/css">
	.elSteps {
		line-height: initial;
	}
	.elSteps .el-step__title {
		color: #409EFF;
		font-size: 12px;
		line-height: 18px;
	}
	.elSteps .el-step__description.is-wait {
		color: #333;
		padding: 0;
	}
</style>

